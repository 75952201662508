
import { Component, ViewChild } from '@angular/core';
import { BaseDetail } from '../../base/detail';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import ClassicEditor from '@libs/ckeditor';
import * as XLSX from "xlsx";
import { Utils } from '@services/utils';
import pupa from 'pupa'
import { PreviewMessage } from '../components/previewMessage/preview-message.component';
import { FormControl, Validators } from '@angular/forms';
import { SelectByPools } from '../components/select-by-pools';
import { DialogService } from '@dialogs/dialog.service';
import { SelectByCarriers } from '../components/select-by-carriers';
@Component({
  selector: '[announcement-detail]',
  templateUrl: './detail.html',
  styleUrls: ['../../detail.scss', './detail.scss']
})

export class AnnouncementDetailComponent extends BaseDetail {
  @ViewChild('fileInput') myFileInput;

  public Editor = ClassicEditor
  public templates = []
  public uploadFiles = []
  public receivers = []
  public headers = []
  public statusHistories = []
  public announcementTypes = [
    {
      key: 'slack',
      value: 'Slack'
    },
    {
      key: 'sms',
      value: 'SMS'
    },
    {
      key: 'email',
      value: 'Email'
    }
  ]
  public objectTypes = [
    {
      key: 'pool',
      value: 'Carrier Pool'
    },
    {
      key: 'carrier',
      value: 'Carrier'
    },
    {
      key: 'client',
      value: 'Client'
    }
  ]
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: 'Name', required: true },
    type: { label: 'Announcement Type', required: true },
    content: { label: 'Content', required: true },
    toField: { label: 'To Field', required: true }, //column người nhận
    title: { label: 'Title', required: false },
    description: { label: 'Description', required: false },
    templateId: { label: 'Template', required: false }
  };

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getTemplates()
    this.getStatusHistories()
  }

  

  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    if(!this.id) return ''
    return Const.APIV2(Const.APIURI_ANNOUNCEMENTS);
  }

  protected afterBindModel(model?: any): void {
    this.receivers = (model?.receivers ?? []).map(item => ({...(item.metadata ?? {}), id: item.id})).filter(item => item)
    if (this.receivers?.length) {
      this.headers = Object.keys(this.receivers?.[0])
    }
    this.mergeStatus()
  }

  protected onGetDetailSuccess(data) {
    // if (!data) return data;
    // this.mergeStatus()
    return data;
  }

  public getReceiver() {
    let type = this.formInput.get('type').value;
    if (type === 'sms') return 'Select Phone Column'
    if (type === 'slack') return 'Select Slack Channel Column'
    if (type === 'email') return 'Select Email Column'
    return 'Please select receiver column'
  }

  public getStatusHistories(){
    if(this.isCreateNew) return;
    this.api.GET(`${Const.APIV2(Const.APIURI_ANNOUNCEMENTS)}/status-histories/${this.id}`).subscribe(
      (response) => {
        this.statusHistories = response?.data?.list_data ?? []
        this.mergeStatus()
      },
      (err) => {
        this.showErr(err);
        
      }
    );
    // status-histories
  }

  public onBtnSave() {
    let data: any = this.formData_JSON(true)
    data.receivers = this.receivers.map(item => {
      return {
        to: item[data.toField],
        metadata: item
      }
    })

    this.api.POST(Const.APIV2(Const.APIURI_ANNOUNCEMENTS), data).subscribe(
      (response) => {
        this.stopProgress();
        this.showSuccess('Template has been created successfully.')
        this.router.navigate([`${this.routeDashboard}/announcements`, response.data.id], { replaceUrl: true });
      },
      (err) => {
        this.showErr(err);
        // this.stopProgress();
        // this.setEnableFormGroup(true);
      }
    );
  }

  public mergeStatus(){
    if(!this.statusHistories?.length || !this.receivers?.length || !this.id){
      return;
    }
    console.log('status ', this.statusHistories)
    console.log('this.receivers ', this.receivers)
    let data = this.receivers ?? []
    for(let d of data){
      let exist = this.statusHistories.find(item => item.id === d.id)
      d.status = exist?.status ?? ''
    }
    this.receivers = [...data]
  }
  public onBack() {
    this.router.navigate([`${this.routeDashboard}/announcements`], { replaceUrl: true });
    // this.needUpdate
  }

  public onChangeContent({ editor }: any) {
    const data = editor.getData();
  }

  public onNamespaceLoaded({ editor }: any) {
    // Add external `placeholder` plugin which will be available for each
    // editor instance on the page.
  }

  public onChangeTemplate(e) {
    let template = this.templates.find(item => item.id === e)
    if (!template) return;
    this.formInput.get('content').setValue(template?.content)
  }

  getTemplates() {
    this.api.GET(Const.APIV2(`${Const.APIURI_TEMPLATES}?limit=-1`)).subscribe(
      (resp) => {
        this.templates = resp?.data?.list_data ?? [];
      },
      (err) => {
      }
    )
  }

  getCarriers() {
    this.api.GET(Const.APIV2(`${Const.APIURI_CARRIERS}?limit=-1`)).subscribe(
      (resp) => {
        // this.templates = resp?.data?.list_data ?? [];
      },
      (err) => {
      }
    )
  }

  getCustomers() {
    this.api.GET(Const.APIV2(`${Const.APIURI_CLIENTS}?limit=-1`)).subscribe(
      (resp) => {
        // this.templates = resp?.data?.list_data ?? [];
      },
      (err) => {
      }
    )
  }

  isRequireEmailTitle() {
    return this.formInput.get('type').value === 'email'
  }

  onCheckRequireTitle() {
    let fc = this.formInput?.get('title');
    // if (fc) {
    //   if (this.isRequireEmailTitle) {
    //     fc.addValidators(Validators.required);
    //   } else {
    //     fc.removeValidators(Validators.required);
    //   }
    //   fc.updateValueAndValidity();
    // }
  }

  onChangeType(e) {
    this.onCheckRequireTitle()
  }

  public async changeListener(files: FileList) {
    if (files && files.length > 0) {
      let file: File = files.item(0);
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      var result = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      if (!result.length) return;
      let headers: any = result[0] ?? []
      headers = headers.map(item => (item ?? '').trim()).filter(item => item)

      let receivers: any[] = []
      result.shift()
      for (let item of result) {
        let data = {}
        for (let i = 0; i < headers.length; i++) {
          data[headers?.[i]] = item[i]
        }
        receivers.push(data)
      }
      this.receivers = receivers
      this.headers = headers
    }
    this.myFileInput.nativeElement.value = '';
    this.formInput.get('toField').setValue(null)
  }

  getVariableName(x: string) {
    return `{{${x}}}`
  }

  copyVariable(x: string) {
    Utils.copyTextToClipboard(this.getVariableName(x), e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Copy variable successful.');
      }
    })
  }

  previewMessage(data) {
    let content = this.formInput.get('content').value ?? ''
    let preData = pupa(content, data, {ignoreMissing: true})
    this.modalService.create({
      nzTitle: 'Preview the Announcement',
      nzContent: PreviewMessage,
      nzCancelText: 'Cancel',
      nzOkText: 'OK',
      nzOnOk: () => {
        return false
      },
      nzComponentParams: {
        previewMessage: preData,
      }
    })
  }

  deleteReceivers(indexs: number[]){
    let data = this.receivers
    for (let i of indexs) {
      data.splice(i, 1);
    }
    this.receivers = [...data];
    console.log('this.receivers ', this.receivers)
  }

  SelectByPools() {
    DialogService.openFormDialog1(SelectByPools, {
      nzComponentParams: {
        closeOnSuccess: true,
        updateSuccess: (receivers) => {
          this.receivers = receivers ?? []
          if (this.receivers?.length) {
            this.headers = Object.keys(this.receivers?.[0])
          }
        },
      },
      nzClassName: "modal-xl",
    });
  }

  SelectByCarriers() {
    DialogService.openFormDialog1(SelectByCarriers, {
      nzComponentParams: {
        closeOnSuccess: true,
        updateSuccess: (receivers) => {
          this.receivers = receivers ?? []
          if (this.receivers?.length) {
            this.headers = Object.keys(this.receivers?.[0])
          }
        },
      },
      nzClassName: "modal-xl",
    });
  }

  clickUpload() {
    this.myFileInput.nativeElement.click()
  }
}