<style>
.apikey-info {
  font-size: 12px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}
.code-block {
  border: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  color: #3e3e3e;
  padding: 15px;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 10px;
}
.code-block pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  margin-right: 30px;
  margin-bottom: 0px;
}
</style>
<div *ngIf="apiKeyInfo" class="apikey-info bottom15">{{apiKeyInfo}}</div>
<div class="code-block">
  <div *ngIf="hasApiKey" style="float: right;">
    <i nz-icon nzType="copy" nzTheme="outline" class="clickable" (click)="copyApiKey(elmApiKey)"></i>
  </div>
  <pre #elmApiKey>{{textApiKey}}</pre>
</div>
<button *ngIf="!hasApiKey" nz-button (click)="onBtnGenerateApiKey()" class="btn2 top15">
  <i *ngIf="isApikeyLoading" nz-icon nzType="loading" nzTheme="outline"></i>
  <i *ngIf="!isApikeyLoading" class="fa fa-key ic-left"></i>
  Generate API key
</button>
<button *ngIf="hasApiKey" nz-button nzDanger (click)="onBtnRevokeApiKey()" class="btn2 top15">
  <i *ngIf="isApikeyLoading" nz-icon nzType="delete" nzTheme="outline"></i>
  <i *ngIf="!isApikeyLoading" class="fa fa-trash-alt ic-left"></i>
  Revoke API key
</button>