<nz-table #virtualTable [nzVirtualItemSize]="54" [nzData]="receivers"
  [nzVirtualForTrackBy]="trackByIndex" [nzFrontPagination]="false" [nzShowPagination]="false"
  [nzScroll]="{ x: '1200px', y: '500px' }" nzBordered>
  <thead>
    <tr>
      <th nzWidth="100px">Index</th>
      <th *ngFor="let h of headers;" nzLeft>{{h}}</th>
      <th *ngIf="isDetail">status</th>
      <th nzRight nzWidth="150px">Action</th>
    </tr>
  </thead>
  <tbody>
    <ng-template nz-virtual-scroll let-data let-index="index">
      <tr>
        <td>{{index+1}}</td>
        <td *ngFor="let h of headers;" [style]="getEmptyStyle(data[h])" nzLeft>{{ data[h] }}</td>
        <td *ngIf="isDetail"><b>{{data?.status}}</b></td>
        <td nzRight>
          <a (click)="preview(data)">Preview</a>
          <a style="margin-left: 8px" (click)="delete(index)">Delete</a>
        </td>
      </tr>
    </ng-template>
  </tbody>
</nz-table>