<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div class="carrier-selection">
  <div class="group-filter">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="8">
        <nz-input-group [nzPrefix]="suffixIconSearch">
          <input type="text" nz-input placeholder="Search by Carrier Pool Name"
            (input)="onSearch($event.target.value)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template>
      </div>
    </div>

  </div>

  <nz-table #rowSelectionTable [nzVirtualItemSize]="54" [nzVirtualForTrackBy]="trackByIndex" [nzFrontPagination]="false"
    [nzShowPagination]="false" [nzScroll]="{ y: '360px' }" [nzData]="listOfData">
    <thead>
      <tr>
        <th nzWidth="70px" [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
        <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortName'>
          Pool Name
        </th>
        <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortDes'>Description</th>
        <th [nzSortOrder]="null" [nzSortDirections]="['ascend', 'descend', null]" [nzSortFn]='sortNumberOfCarrier'>
          Number of Carrier</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of rowSelectionTable.data">
        <td [nzChecked]="setOfCheckedId.has(data.id)" [nzDisabled]="data.disabled"
          (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
        <td>{{ data.name }}</td>
        <td>{{ data.description }}</td>
        <td>
          {{ data.numberOfCarrier }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="setOfCheckedId.size > 0 && !onProgress && !isLoading" [canClickCancel]="!onProgress" labelOK="Add"
  (onOK)="addPools()" (onCancel)="closeDialog()"></div>