<div>
  <form class="form-detail" [formGroup]="formInput" nz-form>
    <div class="group">
      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col [nzSpan]="24">
          <div class="form-label-v2">Type</div>
          <nz-form-item class="mw500">
            <nz-form-control>
              <nz-select formControlName="type" (ngModelChange)="onChangeType($event)">
                <nz-option *ngFor="let option of types" [nzLabel]="option.title" [nzValue]="option.value">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
</div>
