import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { TemplateDetailComponent } from './templates/detail';
import { AnnouncementDetailComponent } from './list/detail';
import { AnnouncementListComponent } from './list/list';
import { TemplateListComponent } from './templates/list';
import { DetailModule } from '../base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { AnnouncementReceivers } from './components/receivers/receivers.component';
import { PreviewMessage } from './components/previewMessage/preview-message.component';
import { SelectReceivers } from './components/selectReceivers/select-receivers.component';
import { SelectByPools } from './components/select-by-pools';
import { SelectByCarriers } from './components/select-by-carriers';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    NzTableModule,
    SearchBoxModule,
    NzDropDownModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzIconModule,
    NzCheckboxModule,
    DetailModule,
    SelectBySearchingModule,
    CKEditorModule,
    NzGridModule,
    NzUploadModule,
    NzTagModule,
    NzEmptyModule
  ],
  declarations: [
    TemplateDetailComponent,
    TemplateListComponent,
    AnnouncementDetailComponent,
    AnnouncementListComponent,
    AnnouncementReceivers,
    PreviewMessage,
    SelectReceivers,
    SelectByPools,
    SelectByCarriers
  ],
  exports: [
    TemplateDetailComponent,
    TemplateListComponent,
  ],
  providers: [
  ]
})
export class AnnouncementModule {}
