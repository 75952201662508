import { Directive, EventEmitter } from "@angular/core";
import { Utils } from "@services/utils";

// T should be BaseComponent
export function mixinHTMLElementWidthChangedNotifier<T extends abstract new (...args: any[]) => any>(Base: T) {
  
  // Notify on parent component, child should extend HTMLElementWidthChangedListener
  @Directive()
  abstract class HTMLElementWidthChangedNotifier extends Base {
    protected abstract get contentElement(): HTMLElement;
    public onContentWidthChanged = new EventEmitter<number>();
    public onContentResponsiveWidthChanged = new EventEmitter<ResponsiveSize>();
    public get pageContentResponsiveWidth() {return this._pageContentResponsiveWidth}
    private _pageContentResponsiveWidth: ResponsiveSize;
    public get pageContentWidth() {return this.contentElement.getBoundingClientRect().width}
  
    ngOnChanges() {
      super.ngOnChanges();
    }
    
    ngOnInit() {
      super.ngOnInit();
    }
  
    ngDoCheck() {
      super.ngDoCheck();
    }
  
    ngAfterContentInit() {
      super.ngAfterContentInit();
    }
  
    ngAfterContentChecked() {
      super.ngAfterContentChecked();
    }
    
    ngAfterViewInit() {
      setTimeout(() => {
        if (this.contentElement) {
          this.onPageContentRectChanged(this.contentElement.getBoundingClientRect());
          new ResizeObserver(entries => {
            this.onPageContentRectChanged(entries[0].target.getBoundingClientRect());
          }).observe(this.contentElement);
        }
      }, 1);
    }
  
    ngAfterViewChecked() {
      super.ngAfterViewChecked();
    }
  
    ngOnDestroy() {
      super.ngOnDestroy();
    }
    
    protected onPageContentRectChanged(value: DOMRect) {
      let bp = Utils.getResponsiveSize(value.width);
      if (bp != this._pageContentResponsiveWidth) {
        this._pageContentResponsiveWidth = bp;
        this.notifyContentResponsiveWidthChanged(bp);
      }
      this.notifyContentWidthChanged(value.width);
    }
    
    protected notifyContentResponsiveWidthChanged(value: ResponsiveSize) {
      let className = this.contentElement.className.trim();
      if (className) {
        className = className.replace(/responsive-(xs|sm|md|lg|xl|xxl)/g, '').trim();
      }
      if (className) {
        className += ' ';
      }
      className += 'responsive-' + value;
      this.contentElement.className = className;
      this.onContentResponsiveWidthChanged.emit(value);
    }
    
    protected notifyContentWidthChanged(value: number) {
      this.onContentWidthChanged.emit(value);
    }
    
    onRouterActivate(compRef) {
      compRef.parentComponent = this;
    }
  }
  
  return HTMLElementWidthChangedNotifier;
}
