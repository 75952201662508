
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'preview-message',
  templateUrl: './preview-message.component.html',
})

export class PreviewMessage  implements OnInit {
  @Input() previewMessage = '';
  ngOnInit(): void {
    
  }
}