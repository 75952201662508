import { Component } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
// import '../../'

@Component( {
  selector: 'template-list',
  templateUrl: './list.html',
  styleUrls: [ '../../list.scss' ]
} )
export class TemplateListComponent extends BaseList{
  txtHintSearch='Search by name, code...'
  protected getApiUrl(): string {
    return Const.APIV2(Const.APIURI_TEMPLATES);
  }
}