import { Component } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
// import 

@Component( {
  selector: 'announcement-list',
  templateUrl: './list.html',
  styleUrls: [ '../../list.scss' ]
} )
export class AnnouncementListComponent extends BaseList{
  protected getApiUrl(): string {
    return Const.APIV2('announcements');
  }
  
  public getFullName(user: any) {
    if(!user) return ''
    if(user.fullName) return user.fullName
    let fullName = user?.firstName ?? ''
    if(user.lastName){
      fullName+= ' ' + user.lastName
    }
    return fullName
  }
}