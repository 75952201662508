import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import _ from 'underscore'

@Component({
  selector: 'select-receivers-by-pool',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class SelectByPools extends BaseFormDialog1 implements OnInit {
  public isError = false;
  public isLoading = false;

  private originList = []
  private keyword: string;

  constructor() {
    super();
  }

  checked = false;
  loading = false;
  indeterminate = false;
  listOfData: readonly any[] = [];
  setOfCheckedId = new Set<string>();

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  trackByIndex(_: number, data: any): number {
    return data.index;
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.listOfData
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  ngOnInit(): void {
    console.log('this.model ', this.model)
    this.onLoadData()
  }

  onLoadData() {
    this.isLoading = true;
    this.api.GET(Const.APIV2(`${Const.APIURI_POOLS}?&limit=-1&filter=${JSON.stringify({subjectType: 'carrier'})}`)).subscribe((response) => {
      let pools = _.get(response, ["data", "list_data"], []);
      pools = pools.map(item => {
        return {
          ...item,
        numberOfCarrier: item?.subjectIds?.length ?? 0
        }
      })
      this.listOfData = pools;
      this.originList = [...pools];
      this.isLoading = false;
    });
  }

  onFilterData() {
    this.listOfData = this.originList.filter(it => this.filterPool(this.keyword, it));
  }

  filterPool(inputValue: string, item: any): boolean {
    if(!inputValue) return true;
    const regexName = new RegExp(inputValue, "i");
    return regexName.test(item.name);
  }

  onSearch(value) {
    this.keyword = value;
    this.onFilterData();
  }

  addPools(): void {
    this.startProgress()
    this.loading = true;
    let carriers = []
    const pools = this.listOfData.filter(data => this.setOfCheckedId.has(data.id));
    for(let pool of pools) {
      const subjectIds = pool.subjectIds || [];
      if(subjectIds.length) {
        const newCarriers = subjectIds.map(it => { return { carrierId: it } });
        carriers = [...carriers, ...newCarriers];
      }
    }
    
    this.api.POST(Const.APIV2(`${Const.APIURI_ANNOUNCEMENTS}/get-receivers`), {
      type: 'carrier',
      ids: carriers.map(item => item.carrierId)
    }).subscribe(
      (response) => {
        let receivers = response?.data?.list_data;
        this.stopProgress();
        this.updateSuccess(receivers)
        this.closeDialog()
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
    setTimeout(() => {
      this.setOfCheckedId.clear();
      this.refreshCheckedStatus();
      this.loading = false;
    }, 1000);
  }

  sortName = (a, b) => a?.name?.localeCompare(b?.name)
  sortDes = (a, b) => (a?.description ?? '')?.localeCompare(b?.description ?? '')
  sortNumberOfCarrier = (a, b) => a?.numberOfCarrier - b?.numberOfCarrier
}
