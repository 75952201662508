
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface VirtualDataInterface {
  index: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'announcement-receivers',
  templateUrl: './receivers.component.html',
})

export class AnnouncementReceivers  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('virtualTable', { static: false }) nzTableComponent?: NzTableComponent<VirtualDataInterface>;
  private destroy$ = new Subject<boolean>();
  @Input() receivers = [];
  @Input() headers = [];
  @Input() isDetail = false;

  @Output("previewMessage") previewMessage: EventEmitter<any> = new EventEmitter();
  @Output("deleteReceivers") deleteReceivers: EventEmitter<any> = new EventEmitter();
  
  scrollToIndex(index: number): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrollToIndex(index);
  }

  trackByIndex(_: number, data: any): number {
    return data.index;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrolledIndexChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: number) => {
        console.log('scroll index to', data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  preview(data){
    this.previewMessage.emit(data)
  }
  delete(index){
    this.deleteReceivers.emit([index])
  }
  getEmptyStyle(data){
    if(!data)
    return 'border: 1px solid red;'
  }
}