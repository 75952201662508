
import { BaseComponent } from '@abstract/BaseComponent';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from '@app/admin/base/detail';
import { Const } from '@const/Const';

@Component({
  selector: 'select-receivers',
  templateUrl: './select-receivers.component.html',
})

export class SelectReceivers  extends BaseDetail {
  ngOnInit(): void {
    this.getPools()
  }

  public types = [
    {
      value: 'pool',
      title: 'Pools'
    }
  ]

  public pools = []

  protected formGroupDeclaration: FormGroupDeclaration = {
    type: { label: 'Type', required: true },
    
  };

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }
  
  onChangeType(e){

  }

  getPools() {
    this.api.GET(Const.APIV2(`${Const.APIURI_POOLS}?limit=-1`)).subscribe(
      (resp) => {
        this.pools = resp?.data?.list_data ?? []
        console.log('this.pools ', this.pools)
      },
      (err) => {
      }
    )
  }



}