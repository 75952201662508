<style>
  .border-right-none {
    border-right-color: transparent !important;
  }
  .right-btn {
    width: 50px; cursor: pointer;
  }
  .avatar {
    width: 40px; height: 40px; cursor: pointer;
  }

  </style>
  <div class="dashboard-child-container no-padding list">
    <div class="list-header">
      <search-box #searchBox (doSearch)="doSearch($event)" placeHolder="Search by name or code" [searchKeyword]="searchKeyword"></search-box>
      <div class="flex1"></div>
      <button *ngIf="requirePermissions([PermissionCode.template.create])" nz-button (click)="onBtnAdd()"><i nz-icon nzType="plus" nzTheme="outline"></i>Create new template</button>
    </div>
  
    <div class="list-body">
      <nz-table
        #nzTable nzBordered="true"
        [nzData]="listData" nzSize="small"
        [nzTotal]="totalCount" [nzFrontPagination]="false"
        [nzShowPagination]="totalCount > listData.length"
        [nzPageSize]="limit"
        [nzLoading]="isLoading"
        [nzLoadingIndicator]="tplLoading"
        [nzNoResult]="tplNoData"
        [nzSize]="'default'"
        (nzPageIndexChange)="onDataListPageChanged($event)">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <thead (nzSortOrderChange)="onSortOrderChange($event)">
          <tr>
            <th nzWidth="200px">Name</th>
            <th>Code</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listData; let i = index">
            <td>
              <a [routerLink]="[routeTemplates, item.id]">{{ item.name || 'N/A'}}</a>
            </td>
            <td>
              <div>{{ item.code }}</div>
            </td>
            <td>
              {{ item.description }}
            </td>
            
          </tr>
        </tbody>
      </nz-table>
    </div>
  
  </div>
  
<!-- 
<nz-table
  #nzTable nzBordered="true"
  [nzData]="listData" nzSize="small"
  [nzTotal]="totalCount" [nzFrontPagination]="false"
  [nzShowPagination]="totalCount > listData.length"
  [nzPageSize]="limit" [nzPageIndex]="pageIndex"
  [nzLoading]="isLoading"
  [nzLoadingIndicator]="tplLoading"
  [nzNoResult]="tplNoData"
  [nzSize]="'default'"
  (nzPageIndexChange)="onDataListPageChanged($event)">
  <ng-template #tplNoData>
    <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
  </ng-template>
  <ng-template #tplLoading>
    <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  </ng-template>
  <thead (nzSortOrderChange)="onSortOrderChange($event)">
    <tr>
      <th nzWidth="200px">Customer</th>
      <th>Request By</th>
      <th>Request At</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of listData; let i = index">
      <td>
        {{ item.client?.name || 'N/A'}}
      </td>
      <td>
        <div>{{ item.requestData?.filledBy }}</div>
        <div class="request-by-email">{{ item.requestData?.requestByEmail }}</div>
      </td>
      <td>
        {{ item.createRequestTime }}
      </td>
      
    </tr>
  </tbody>
</nz-table> -->