import { Component, Input } from '@angular/core';
import { ApiKey } from '@wearewarp/types/data-model'
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import { Utils } from '@services/utils';

@Component({
  selector: '[apikey]',
  templateUrl: './comp.html',
})
export class ApiKeyComponent extends BaseComponent {
  private _model;
  
  @Input() get model() {return this._model}
  set model(value) {
    this._model = value;
    if (value) {
      this.apiKey = value.apiKey;
    }
  }
  
  private _apiKey: ApiKey;
  public get apiKey(): ApiKey {return this._apiKey}
  public set apiKey(value: ApiKey) {
    this._apiKey = value;
    this.apiKeyInfo = '';
    if (!value) return;
    let action, time, user;
    if (value.delete) {
      action = 'Revoked';
      time = value.delete.when;
      user = value.delete.byUser
    } else {
      action = 'Created';
      time = value.create.when;
      user = value.create.byUser
    }
    this.apiKeyInfo = `${action} since ${this.displayDateTimeDB(time, 'D/M/YYYY H:mm')}, by ${this.getFullName(user)}`
  }
  get hasApiKey(): boolean {
    return !!this.apiKey?.token ?? false;
  }
  get textApiKey(): string {
    let txt = this.apiKey?.token;
    if (!txt) {
      txt = 'N/A';
    }
    return txt;
  }
  public apiKeyInfo = '';
  public isApikeyLoading = false;
  
  constructor() {
    super();
  }
  
  onBtnGenerateApiKey() {
    this.updateApiKey('create_apikey');
  }
  
  onBtnRevokeApiKey() {
    this.confirmDeletion({
      message: `Revoke API key?`,
      fnOk: () => this.updateApiKey('delete_apikey', 'Api key has already been revoked'),
      txtBtnOk: 'Revoke'
    });
  }
  
  private getApiUrlPrefix() {
    return `${Const.APIURI_DEVELOPERS}/${this.model._id}`;
  }
  
  private updateApiKey(action: 'create_apikey'|'delete_apikey', successMessage: string = null) {
    let url = this.getApiUrlPrefix();
    if (!url) return;
    url += '/' + action;
    this.isApikeyLoading = true;
    this.api.POST(url).subscribe(
      resp => {
        if (successMessage) {
          this.showSuccess(successMessage);
        }
        this.apiKey = resp.data;
        this.isApikeyLoading = false;
      }, err => {
        this.showErr(err);
        this.isApikeyLoading = false;
      }
    );
  }
  
  copyApiKey(elmApiKey: HTMLElement) {
    let token = this.apiKey?.token;
    if (!token) {
      return;
    }
    Utils.copyTextToClipboard(token, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Api key has already been copied to the clipboard');
        Utils.selectAll(elmApiKey);
      }
    })
  }
}