<div class="dashboard-child-container user-detail no-padding announcement-container">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <div class="box-with-title">
      <div class="box-title">Information</div>
      <div class="box-content">
        <div class="group" [ngClass]="{'flex': !isMobile}">
          <div nz-row style="max-width: 1024px;" [nzGutter]="[16, 16]">
            <div nz-col [nzSpan]="12">
              <div class="form-label-v2">Name<span class="label-mark-required"></span></div>
              <nz-form-item class="mw500">
                <nz-form-control>
                  <input nz-input formControlName="name" (input)="onInputChanged($event, 'name')"
                    (keypress)="onInputKeyPress($event, 'name')">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <div class="form-label-v2">Template</div>
              <nz-form-item class="mw500">
                <nz-form-control>
                  <nz-select formControlName="templateId" (ngModelChange)="onChangeTemplate($event)">
                    <nz-option *ngFor="let option of templates" [nzLabel]="option.name" [nzValue]="option.id">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <div class="form-label-v2">Announcement Type<span class="label-mark-required"></span></div>
              <nz-form-item class="mw500">
                <nz-form-control>
                  <nz-select formControlName="type" (ngModelChange)="onChangeType($event)">
                    <nz-option *ngFor="let option of announcementTypes" [nzLabel]="option.value" [nzValue]="option.key">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <input type="file" class="upload" style="display:none" #fileInput
              (change)="changeListener($event.target.files)">
            <!-- <div nz-col [nzSpan]="6">
              <div class="form-label-v2">Upload Receivers<span class="label-mark-required"></span></div>
              <button nz-button nzType="primary" (click)="clickUpload()">Upload Now</button>
              <input type="file" class="upload" style="display:none" #fileInput
                (change)="changeListener($event.target.files)">
            </div>
            <div nz-col [nzSpan]="6" *ngIf="headers.length && formInput.get('type').value && isCreateNew">
              <div class="form-label-v2">{{getReceiver()}}<span class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <nz-select formControlName="toField">
                    <nz-option *ngFor="let option of headers" [nzLabel]="option" [nzValue]="option">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div> -->
            <div nz-col [nzSpan]="12" *ngIf="isRequireEmailTitle()">
              <div class="form-label-v2">Email Title<span class="label-mark-required"></span></div>
              <nz-form-item class="mw500">
                <nz-form-control>
                  <input nz-input formControlName="title" (input)="onInputChanged($event, 'name')"
                    (keypress)="onInputKeyPress($event, 'name')">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="24">
              <div class="form-label-v2">Content<span class="label-mark-required"></span></div>
              <ckeditor formControlName="content" (namespaceLoaded)="onNamespaceLoaded($event)"
                (change)="onChangeContent($event)" [editor]="Editor" data=""></ckeditor>
            </div>
            <div style="margin-left: 8px;" *ngIf="headers?.length">
              Possible variables you can use are
              <nz-tag style="cursor: pointer;" (click)="copyVariable(h)" *ngFor="let h of headers; let i = index">
                <span> {{ getVariableName(h) }}</span>
                <span nz-icon nzType="copy"></span>
              </nz-tag>
            </div>
            <div nz-col nzSpan="24">
              <button nz-button (click)="onBack()" style="margin-right: 16px;">Back</button>
              <button nz-button *ngIf="isCreateNew" [disabled]="!needUpdate || !receivers?.length" (click)="onBtnSave()"
                nzType="primary">
                <span nz-icon nzType="send"></span>
                Send Announcement
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-with-title top20">
      <div class="box-title">Receivers</div>
      <div class="box-content">
        <div style="margin: 16px; margin-top: 0px;">
          <div nz-row>
            <div nz-col style="margin-top: 22px;">
              <button nz-button  nzType="default" (click)="clickUpload()">Upload</button>
              <button nz-button  nzType="default" style="margin-left: 8px;" (click)="SelectByPools()">Select Pools</button>
              <!-- <button nz-button  nzType="default" style="margin-left: 8px;" (click)="SelectByCarriers()">Select Carriers</button>
              <button nz-button  nzType="default" style="margin-left: 8px;" (click)="SelectByPools()">Select Customers</button> -->
            </div>
            <div nz-col style="margin-left: 16px;" *ngIf="receivers?.length">
              <div class="form-label-v2">{{getReceiver()}}<span class="label-mark-required"></span></div>
              <nz-form-item style="width: 260px;">
                <nz-form-control>
                  <nz-select formControlName="toField" [nzPlaceHolder]="getReceiver()">
                    <nz-option *ngFor="let option of headers" [nzLabel]="option" [nzValue]="option">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>


          <announcement-receivers [isDetail]="!isCreateNew" *ngIf="receivers?.length" (deleteReceivers)="deleteReceivers($event)"
            (previewMessage)="previewMessage($event)" [receivers]="receivers"
            [headers]="headers"></announcement-receivers>
          <nz-empty *ngIf="!receivers?.length"
            nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="footerTpl">
            <ng-template #contentTpl>
              <span>
                Please upload your contact files or Select from system!
                <!-- <a>View instruction</a> -->
              </span>
            </ng-template>
            <ng-template #footerTpl>
              <a nz-button nzType="link" (click)="clickUpload()" nzBlock>Upload</a>
            </ng-template>
          </nz-empty>
        </div>
      </div>
    </div>
  </form>
</div>